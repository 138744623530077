import React, { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    // Create the script element
    const script1 = document.createElement('script');
    script1.src = `https://www.googletagmanager.com/gtag/js?id=G-0CKEYXHJ9C`;
    script1.async = true;

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-0CKEYXHJ9C');
    `;

    // Append the script element to the document head
    document.head.appendChild(script1);
    document.head.appendChild(script2);

    // Cleanup function to remove the scripts when the component unmounts
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);

  return null;
};

export default GoogleAnalytics;
