import { useContext, useEffect  } from "react";
import { ClickContext } from './App';
import useViewport from './getViewport';

const SearchBar = () => {

    const {handleClick, onClick, handleSearchClick, onSearchClick} = useContext(ClickContext);
    const breakpoints = {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        };
      
        const { width } = useViewport();
        const breakpoint = breakpoints.md;
        
        // If the burger menu or searchBar are open, if the viewport size > md reset the icons to default 
        useEffect(() => {
            if (width > breakpoint) {
                if (onClick) {
                    handleClick();
                } else if (onSearchClick) {
                    handleSearchClick();
                }
            }
        }, [width, breakpoint, onClick, onSearchClick, handleClick, handleSearchClick]);

    return ( 
        <div className="top-0 left-0 md:hidden">
            <div className="absolute inset-0 h-screen w-full bg-[url('./Images/Pexels/pexels-cottonbro-studio-6673595.jpg')] bg-hero bg-cover bg-center bg-no-repeat opacity-20"/>
            <div class="flex justify-center">
                <div class="mt-10">
                <input type="text" className="z-10 relative border w-72 border-black bg-gray-200 p-1 placeholder-black" placeholder="Search listings" />
                </div>
            </div>
            

        </div>
     );
}
 
export default SearchBar;