import hiwImage from './Images/Pexels/pexels-alex-haraus-2847362.jpg'

const HowItWorks = () => {
    return (
        <div className="bg-blue-50">
            <div className="flex flex-col md:flex-row m-5 flex-wrap md:flex-nowrap items-center place-items-stretch justify-center mt-2">
                <div className="flex-1 md:relative  md:w-1/3 ">
                    <h1 className="md:text-4xl lg:text-5xl font-dm-sans drop-shadow-lg font-medium first:w-3/4 md:absolute md:bottom-1/3 ml-5 ">HOW IT WORKS</h1>
                </div>
                <div className="items-start flex flex-col w-full place-items-stretch md:w-1/3 lg:w-2/5">
                    <div className=" flex flex-col  md:w-64 lg:w-fit p-5 space-y-6 ">
                        <div className="  rounded-xl border-2  lg:p-2 xl:p-9 border-gray-600 flex-grow">
                            <h1 className="text-wrap font-medium s:text-base md:text-normal lg:text-2xl font-dm-sans p-6">1. MAKE A LISTING WITH THE DETAILS OF YOUR CLIMBING SHOES</h1>
                        </div>

                        <div className="  rounded-xl border-2 lg:p-2 xl:p-9 border-gray-600 flex-grow" >
                            <h1 className="text-wrap font-medium sm:text-sm md:text-normal lg:text-2xl font-dm-sans p-6 ">2. SET YOUR LISTING TO SALE, SWAP OR BOTH</h1>
                        </div>

                        <div className="  rounded-xl border-2 lg:p-2 xl:p-9 border-gray-600 flex-grow">
                            <h1 className="text-wrap font-medium sm:text-sm md:text-normal lg:text-2xl font-dm-sans p-6">3. MAKE THE SWAP OR SALE!</h1>
                        </div>
                    </div>
                </div>
                <div className="invisible  md:visible  flex-none md:w-1/3">
                    <img class="object-cover rounded-2xl " src={hiwImage} alt="climber" />
                </div>
            </div>
        </div>

    );
}

export default HowItWorks;