import { Link } from 'react-router-dom'

const Navbar = () => {
    // const logout = () => {
    //     auth.signOut();
    //     console.log(user);
    //   };

    return ( 
        <nav className="hidden md:block z-20 bg-white">
            <div className='flex justify-between p-3'>
                <Link to="/" className="navbaritem1">HOME</Link> 

                <div className="group browseby hover:text-gray-700 hidden md:block ml-2 mr-2 md:text-lg">
                    <button>BROWSE BY</button>
                    <div className="browseby-content bg-slate-50 z-20 hidden absolute group-hover:block text-left w-28 shadow-inner">
                        <Link to="/" className="navbaritem1 hover:text-black text-gray-700 p-1">Women</Link> 
                        <Link to="/" className="navbaritem1 hover:text-black text-gray-700 p-1">Men</Link> 
                        <Link to="/" className="navbaritem1 hover:text-black text-gray-700 p-1">Brands</Link> 
                    </div>
                </div>
                <Link to="/HowItWorks" className="navbaritem1">HOW IT WORKS</Link> 
                {/* <Link to="/SwapLocations" className="navbaritem1">SWAP LOCATIONS</Link>  */}
                <Link to="/MakeAListing" className="navbaritem1">MAKE A LISTING</Link> 
                <div className="flex">
                    {false ? (
                        <>
                            {/* <div>Logged in with {user.email}</div>
                            <button className="navbaritem1" onClick={logout}>LOGOUT</button> */}
                            <div className="group browseby hover:text-gray-700 hidden md:block ml-2 mr-2 md:text-lg relative">
                                <button>ACCOUNT</button>
                                <div className="browseby-content bg-slate-50 z-20 hidden absolute group-hover:block text-left w-36 shadow-inner right-0">
                                    <Link to="Account" className="navbaritem1 hover:text-black text-gray-700 p-1">My Account</Link> 
                                    <Link to="/" className="navbaritem1 hover:text-black text-gray-700 p-1">My Listings</Link> 
                                    <Link to="/" className="navbaritem1 hover:text-black text-gray-700 p-1">Messages</Link> 
                                    {/* <Link to="/" className="navbaritem1 hover:text-black text-gray-700 p-1" onClick={logout}>Log out</Link>  */}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <Link to="/Register" className="navbaritem1">REGISTER</Link>
                            <Link to="/SignIn" className="navbaritem1">SIGN IN</Link>
                        </>
                    )}
                    
                </div>
            </div>
        </nav>
        
        
     );
}

export default Navbar;