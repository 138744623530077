import csLogo from './Images/Logos/Logo_v3.png'
import { Link } from 'react-router-dom'
import { useContext } from "react";
import { ClickContext } from './App';

const LogoHeader = () => {
    const {handleClick, onClick, handleSearchClick, onSearchClick} = useContext(ClickContext);
    return ( 
        <div className="bg-logoColor h-28 flex flex-row justify-between items-center z-20">   
            <div className="cursor-pointer md:hidden ml-5" onClick={handleClick}>
                {!onClick && !onSearchClick && (
                        // Render burger icon by default
                        <svg className="w-10 h-10 stroke-white" id="burger" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" stroke-width="1.5"  >
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                    )}
            </div>
            
            <div className="flex items-center h-24"> 
            <Link to="/" className="flex items-center sm:justify-start" onClick= { onClick ? handleClick : onSearchClick ? handleSearchClick : null } >
                <img src={csLogo} alt="ClimbSwapLogo" className="h-14 md:h-20  md:ml-2 bg-logoColor " />
                <h1 className="text-white bg-logoColor object-cover font-quicksand tracking-wide text-3xl md:text-6xl pl-3">CLIMB SWAP</h1>
            </Link>
            </div>

            <input type="text" className="border border-gray-400 w-48 bg-gray-100 placeholder-gray-600 pl-1 hidden md:block z-10 justify-end mr-4" placeholder="Search listings" />
            <div className="cursor-pointer md:hidden mr-5" id="magnifying-glass" onClick={onClick ? handleClick : handleSearchClick}>
                {onSearchClick || onClick ? (
                    // Render cross icon when clicked
                    <svg className="w-10 h-10 stroke-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                    ) : (
                    //Render magnifying glass by default
                    <svg className ="w-7 h-7 stroke-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" >
                        <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                    </svg> 
                )}
            </div>
        </div>          
     );
}

export default LogoHeader;