import csLogo from './Images/Logos/Logo_v3.png'
import climber from './Images/Icons/climber.png'
import climber1 from './Images/Icons/climber1.png'
import shoe from './Images/Icons/climbing-shoes.png'
import shoe1 from './Images/Icons/climbing-shoes1.png'
import money from './Images/Icons/money.png'
import instagram from './Images/Icons/instagram_circle.png'
import twitter from './Images/Icons/twitter.png'
import facebook from './Images/Icons/facebook.png'
import GoogleAnalytics from './GoogleAnalytics'

const LandingPage = () => {

    // const mainText = "If your climbing shoes don’t fit but you don’t want to spend money on a brand new pair Climb Swap has the chance to connect you with your perfect climbing shoes. \nSwap your current shoes with someone who has your size or sell them to someone they'll fit. \n\nSign up to our mailing list to receive updates on our launch.";

    const text1 = "Discover other climbers looking for your shoes. List your unwanted climbing shoes."
    const text2 = "Find your perfect size. Browse shoe listings of other climbers looking to sell or swap new or almost new shoes."
    const text3 = "Save money by swapping or make money by selling your ill fitting climbing shoes."

    const handleButtonClick = () => {
        window.open("https://dashboard.mailerlite.com/forms/907853/119494566181078041/share", "_blank");
      };

    return ( 
        <div className="flex flex-col ">
            <GoogleAnalytics />
            <div className="bg-logoColor h-24 flex flex-row items-center justify-center z-10"> 
                <img src={csLogo} alt="ClimbSwapLogo" className="md:h-16 h-11 md:pl-0 pl-2 bg-logoColor " />
                <h1 className="text-white bg-logoColor object-cover font-quicksand tracking-wide lg:text-6xl text-2xl md:pl-3 md:w-auto w-72">
                    CLIMB SWAP </h1>
                    <h2 className="text-orange-400 tracking-wide lg:text-6xl text-2xl italic md:pl-3 md:w-auto w-72">COMING SOON</h2>
            </div>
            <div className="h-full">
                <div className="z-0 fixed inset-0 opacity-10 bg-[url('./Images/Pexels/1pexels-cottonbro-studio-6674274.jpg')] bg-hero bg-cover bg-center bg-no-repeat " />
                
                <div className='flex flex-col z-20 justify-center'>
                    <div className='flex justify-center '>
                        <h1 className='z-20 text-gray-800 md:leading-none leading-normal text-3xl lg:text-6xl font-semibold md:mt-7 mt-7 w-96 md:w-auto '>
                            Sign up to follow the launch</h1>
                    </div>

                    <div className='flex justify-center items-center flex-grow'>
                        <button className='z-20 bg-logoColor w-64 h-16 text-white font-semibold text-3xl drop-shadow-lg mt-3 rounded-md' onClick={handleButtonClick}>Sign up for free</button>
                    </div>

                    <div className='z-20 flex md:items-center  md:justify-normal justify-center md:w-boxWidthMd lg:w-boxWidthLg mx-auto'>
                        <div className='z-20 bg-white md:mt-5 mt-3 md:w-boxWidthMd lg:w-boxWidthLg md:h-80 drop-shadow-lg flex md:flex-row flex-col w-mainTextWidth mb-4'>
                            <div className='p-10'>
                                <div className='flex flex-row justify-center mb-10'> 
                                    <img src={climber} alt="ClimbSwapLogo" className="h-14 lg:h-20 lg:mt-1 md:ml-2" />
                                    <img src={climber1} alt="ClimbSwapLogo" className="h-14 lg:h-20 lg:mt-1 md:ml-2" />
                                </div>
                                <p className='text-xl md:-mt-4'>{text1}</p>
                            </div>
                            <div className='p-10'>
                            <div className='flex flex-row justify-center mb-10'> 
                                    <img src={shoe} alt="ClimbSwapLogo" className="h-14 lg:h-20 lg:mt-1 md:ml-2" />
                                    <img src={shoe} alt="ClimbSwapLogo" className="h-14 lg:h-20 lg:mt-1 md:ml-2" />
                                </div>
                                <p className='text-xl md:-mt-4'>{text2}</p>
                            </div>
                            <div className='p-10'> 
                            <div className='flex flex-row justify-center mb-10'> 
                                    <img src={money} alt="ClimbSwapLogo" className="h-14 lg:h-20 lg:mt-1 md:ml-2" />
                                    <img src={shoe1} alt="ClimbSwapLogo" className="h-14 lg:h-20 lg:mt-1 md:ml-2" />
                                </div>
                                <p className='text-xl lg:w-44 md:-mt-4'>{text3}</p>
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-center z-10 mb-5'>
                        <a href="https://www.instagram.com/climb.swap/" target="_blank" rel="noreferrer">
                            <img src={instagram} alt="instagram icon" className="h-11 lg:h-11" />
                        </a>
                        <a href="https://x.com/Climbswap" target="_blank" rel="noreferrer">
                            <img src={twitter} alt="twitter icon" className="h-11 lg:h-11 ml-4" />
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61560291845632" target="_blank" rel="noreferrer">
                            <img src={facebook} alt="facebook icon" className="h-11 lg:h-11 ml-4" />
                        </a>
                    </div>
                    

                    {/* <div className='flex flex-col justify-center'>
                        <div className=' mt-7 text-xl lg:text-2xl lg:max-w-none mx-auto'>
                            <p className='whitespace-pre-wrap text-left w-mainTextWidth md:w-mainTextWidthMd lg:w-mainTextWidthLg'>
                                {mainText}</p>
                        </div>
                    </div> */}

                    
                </div>
            </div>
        </div>
     );
}
 
export default LandingPage;