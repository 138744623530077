const ContactUs = () => {
    return ( 
        <div className='bg-blue-50 min-h-screen'>
            <div className="h-full flex flex-row justify-stretch">
                <h1 className="w-4/12 text-5xl pt-20 drop-shadow-lg text-gray-700">Contact Us</h1>
                <p className="w-8/12 text-left text-lg pt-24">For questions and queries email us at team@climbswap.com</p>
            </div>
        </div>
     );
}
 
export default ContactUs;