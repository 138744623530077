import csLogo from './Images/Logos/Logo_v3.png'
import { Link } from 'react-router-dom'
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';


const SignIn = () => {
    const navigate = useNavigate();
    const loading = false;
    useEffect(() => {
        if (loading) {
          // maybe trigger a loading screen
          return;
        }
        // if (user) navigate("/");
      }, [ loading]);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const signin = async (e) => {
        e.preventDefault();
        try {
            // await logInWithEmailAndPassword(email, password);
            // Reset form after successful signin
            setEmail('');
            setPassword('');
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    };

    return ( 
        <div className="min-h-screen bg-logoColor flex flex-col items-center p-3">
            <div className="mb-3"> 
                <Link to="/" className="flex items-center sm:justify-start"  >
                    <img src={csLogo} alt="ClimbSwapLogo" className="h-14 md:h-20 md:mt-1 md:ml-2 bg-logoColor " />
                    <h1 className="text-white bg-logoColor object-cover font-quicksand tracking-wide text-3xl md:text-6xl pl-3">CLIMB SWAP</h1>
                </Link>
            </div>
            
            <div className="w-96 h-signinheight md:w-signinwidth bg-slate-50 rounded-lg flex flex-col items-center">

                <div className="w-full flex justify-evenly">
                    <div className='border-b-2 md:w-60 w-40 hover:border-gray-300'>
                        <Link to="/Register">
                        <h1 className='m-3 text-gray-700 text-xl '>REGISTER</h1>
                        </Link>
                    </div>
                    
                    <div className='border-l-2 h-5/6 mt-1'></div>
                    <div className='border-b-2 md:w-60 w-40 border-gray-700'>
                        <h1 className='m-3 text-gray-700 text-xl'>SIGN IN</h1>
                    </div>
                </div>    
                 
                    
                <div className='w-80 justify-center pt-8'>
                <h1 className='font-semibold text-lg p-3 pb-1'>Sign in to your account</h1>
                    <form className="register-form">
                    <div className='input-field p-3'>
                        <label htmlFor="email" className="block text-base font-medium text-gray-700 text-left">EMAIL ADDRESS</label>
                        <input
                            id="signin-email"
                            name="signin-email"
                            type="email"
                            autoComplete="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                        />
                    </div>

                    <div className="input-field p-3">
                        <label htmlFor="password" className="block text-left text-base font-medium text-gray-700">PASSWORD</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                        />
                        <Link to="/" className="flex items-center sm:justify-start font-semibold text-sm text-gray-700"  >Forgot password</Link>
                    </div>

                    <div className='pl-5 pr-5 pt-5'>
                        <button
                        type="submit"
                        className="mb-7 flex w-full justify-center rounded-full bg-slate-100 hover:bg-slate-200 border-2 border-slate-400 hover:border-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-700 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                        onClick={signin}>SIGN IN</button>
                        <h1 className='mb-3'>Or sign in with</h1>
                        <button
                        type="submit"
                        className="mb-3 flex w-full justify-center rounded-full bg-slate-100 hover:bg-slate-200 border-2 border-slate-400 hover:border-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-700 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                        // onClick={signInWithGoogle}
                        >Google</button>
                        <button
                        type="submit"
                        className="mb-3 flex w-full justify-center rounded-full bg-slate-100 hover:bg-slate-200 border-2 border-slate-400 hover:border-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-700 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                        >Apple</button>
                        <button
                        type="submit"
                        className="mb-3 flex w-full justify-center rounded-full bg-slate-100 hover:bg-slate-200 border-2 border-slate-400 hover:border-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-700 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                        >Facebook</button>
                    </div>
                    
                </form>
                </div>
                
            </div>
        </div>
     );
}
 
export default SignIn;