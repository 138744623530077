import { Link } from 'react-router-dom'


const Account = () => {
    return ( 

        <div className="grid grid-cols-6 gap-4">
            <div className="col-start-2 col-span-1 text-2xl text-left">My Account</div>
            {/* <div className="col-start-3 col-end-7 text-2xl">{user.email}</div> */}
            <div className='col-start-1 col-end-7 border-t-2 border-gray-400 mr-52 ml-52'></div>
            <div className="col-start-2 col-end-3">
                <div className='flex flex-col items-start text-xl'>
                    <Link to="/Account/Listings" className='mt-2' >My Listings</Link>
                    <Link to="/Account" className='mt-2'>Messages</Link>
                    <Link to="/Account" className='mt-2'>Saved listings</Link>
                    <Link to="/Account" className='mt-2'>Saved searches</Link>
                </div>
            </div>
            <div className="col-start-3 col-end-7">
                
            </div>
        </div>
     );
}
 
export default Account;