
const Home = () => {
    return ( 
        <div className="flex h-screen justify-center">
            <div className="absolute inset-0 opacity-20 bg-[url('./Images/Pexels/pexels-cottonbro-studio-6673595.jpg')] bg-hero bg-cover bg-center bg-no-repeat" />
            <div className='relative w-full lg:w-3/4 md:w-11/12'>
                <div className="absolute inset-0 bg-[url('./Images/Pexels/pexels-cottonbro-studio-6701735.jpg')] bg-hero bg-cover bg-center bg-no-repeat" />
                <h1 className="z-10 text-5xl md:text-7xl tracking-wider text-white relative pt-5">Find your perfect fit.</h1>
            </div>
        </div>
     );
}

export default Home;