import csLogo from './Images/Logos/Logo_v3.png'
import { Link, useNavigate } from 'react-router-dom'
import React, { useState, useEffect } from "react";


const Register = () => {
    const navigate = useNavigate();
    // const [user, loading, error] = useAuthState(auth);
    const loading = false;
    useEffect(() => {
        if (loading) return;
        // if (user) navigate("/");
      }, [loading]);

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const register = async (e) => {
        e.preventDefault();
        try {
            
            // await registerWithEmailAndPassword(name, surname, email, password);
            // Reset form after successful registration
            setEmail('');
            setPassword('');
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    };

    return ( 
        <div className="min-h-screen bg-logoColor flex flex-col items-center p-3">
            <div className="mb-3"> 
                <Link to="/" className="flex items-center sm:justify-start"  >
                    <img src={csLogo} alt="ClimbSwapLogo" className="h-14 md:h-20 md:mt-1 md:ml-2 bg-logoColor " />
                    <h1 className="text-white bg-logoColor object-cover font-quicksand tracking-wide text-3xl md:text-6xl pl-3">CLIMB SWAP</h1>
                </Link>
            </div>

            <div className="w-96 h-registerheight md:w-signinwidth bg-slate-50 rounded-lg flex flex-col items-center">

                <div className="w-full flex justify-evenly mb-5">
                    <div className='border-b-2 md:w-60 border-gray-700 w-40'>
                        <h1 className='m-3 text-gray-700 text-xl '>REGISTER</h1>
                    </div>
                    
                    <div className='border-l-2 h-5/6 mt-1'></div>
                    <div className='border-b md:w-60 hover:border-gray-300 w-40'>
                        <Link to="/SignIn">
                            <h1 className='m-3 text-gray-700 text-xl'>SIGN IN</h1>
                        </Link>
                    </div>
                </div>    

                <h1 className='m-3 text-lg font-semibold'>Register with</h1>

                <div className='flex flex-row'>
                    <button
                    type="submit"
                    className="m-1 flex md:w-32 w-24 justify-center rounded-full bg-slate-100 hover:bg-slate-200 border-2 border-slate-400 hover:border-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-700 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                    // onClick={signInWithGoogle}
                     >Google</button>
                    <button
                    type="submit"
                    className="m-1 flex md:w-32 w-24 justify-center rounded-full bg-slate-100 hover:bg-slate-200 border-2 border-slate-400 hover:border-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-700 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                    >Apple</button>
                    <button
                    type="submit"
                    className="m-1 flex md:w-32 w-24 justify-center rounded-full bg-slate-100 hover:bg-slate-200 border-2 border-slate-400 hover:border-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-700 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                    >Facebook</button>
                </div>
                    
                <div className='w-80 justify-center pt-8'>
                    <h1 className='font-semibold text-lg p-3 pb-1'>Create an account</h1>

                    <form className="register-form">
                        <div className='input-field p-3'>
                            <label htmlFor="name" className="block text-base font-medium text-gray-700 text-left">NAME</label>
                            <input
                                id="signup-name"
                                name="signup-name"
                                type="text"
                                autoComplete="name"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        <div className='input-field p-3'>
                            <label htmlFor="surname" className="block text-base font-medium text-gray-700 text-left">SURNAME</label>
                            <input
                                id="signup-surname"
                                name="signup-surname"
                                type="text"
                                autoComplete="surname"
                                required
                                value={surname}
                                onChange={(e) => setSurname(e.target.value)}
                                className="block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        <div className='input-field p-3'>
                            <label htmlFor="email" className="block text-base font-medium text-gray-700 text-left">EMAIL ADDRESS</label>
                            <input
                                id="signup-email"
                                name="signup-email"
                                type="email"
                                autoComplete="email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                            />
                        </div>

                        <div className="input-field p-3">
                            <label htmlFor="password" className="block text-left text-base font-medium text-gray-700">PASSWORD</label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                            />
                        <button
                        type="submit"
                        className="mt-6 flex w-full justify-center rounded-full bg-slate-100 hover:bg-slate-200 border-2 border-slate-400 hover:border-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-700 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                        onClick={register}>REGISTER</button>
                    
                    </div>

                    
                    
                </form>
                </div>
                
            </div>
        </div>
     );
}
 
export default Register;