import AboutUsImage from './Images/Pexels/pexels-allan-mas-5383477.jpg'

const AboutUs = () => {
    return ( 
        <div className=' bg-blue-50 min-h-screen'>
            <div className="flex flex-row justify-evenly ">
                <h1 className="w-4/12 text-5xl pt-20 drop-shadow-lg text-gray-700">About Us</h1>

                <div className='flex justify-stretch h-full w-9/12 pt-20'>
                    <p className="w-1/2 text-left text-lg">
                    This website was created by another climber who has bought several
                    climbing shoes only to realise they immediately regretted it after the
                    first few climbs. <br/> <br/>
                    
                    Unable to return worn shoes they wanted a way to swap
                    their shoes with other climbers who've made the same mistake to find the
                    right pair.
                    </p>
                    <img src={AboutUsImage} alt="me climbing" className=' rounded-2xl w-1/3 ml-10 '/>  
                </div>
            </div>
        </div>
       
     );
}
 
export default AboutUs;