import { Link } from 'react-router-dom'
import { useContext, useEffect } from "react";
import { ClickContext } from './App';
import useViewport from './getViewport';

const BurgerMenu = () => {
    const loading = false;
    const user = false;

    const {handleClick, onClick, handleSearchClick, onSearchClick} = useContext(ClickContext);
    const breakpoints = {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        };
      
        const { width } = useViewport();
        const breakpoint = breakpoints.md;
        
        // If the burger menu or searchBar are open, if the viewport size > md reset the icons to default 
        useEffect(() => {
            if (width > breakpoint) {
                if (onClick) {
                    handleClick();
                } else if (onSearchClick) {
                    handleSearchClick();
                }
            }
        }, [width, breakpoint, onClick, onSearchClick, handleClick, handleSearchClick]);
    
        // const [user, loading, error] = useAuthState(auth);
        // const logout = () => {
        //     auth.signOut();
        //     console.log(user);
        // };    

    return ( 
        <div className="top-0 left-0 md:hidden">
            <div className="absolute inset-0 h-screen w-full bg-[url('./Images/Pexels/pexels-cottonbro-studio-6673595.jpg')] bg-hero bg-cover bg-center bg-no-repeat opacity-20" />   
            <ul className="list-none p-5 relative z-10">
                <li>
                    <Link to="/" className="block slide-out-menu text-left pb-3">WOMEN</Link>     
                </li>
                <li>
                    <Link to="/" className="block slide-out-menu text-left pb-3">MEN</Link> 
                </li>
                <li>
                    <Link to="/" className="block slide-out-menu text-left pb-3">BRANDS</Link> 
                </li>
                <li>
                    <Link to="/HowItWorks" className="block slide-out-menu text-left pb-3" onClick={handleClick}>HOW IT WORKS</Link> 
                </li>
                <li>
                    <Link to="/SwapLocations" className="block slide-out-menu text-left pb-3" onClick={handleClick}>SWAP LOCATIONS</Link> 
                </li>
                <li>
                    <Link to="/make_a_listing" className="block slide-out-menu text-left pb-3 border-b-2 border-gray-800" onClick={handleClick}>MAKE A LISTING</Link> 
                </li>

                {!loading && !user && (
                    <>
                    <li>
                        <Link to="/Register" className="block slide-out-menu text-left pb-3 pt-3" onClick={handleClick}>Register</Link> 
                    </li>
                    <li>
                        <Link to="/SignIn" className="block slide-out-menu text-left pb-3" onClick={handleClick}>Sign In</Link> 
                    </li>
                    </>
                )}
                {!loading && user && (
                    <li>
                    <button className="block slide-out-menu text-left pt-3 pb-3" 
                    // onClick={logout}
                    >Logout</button>
                    </li>
                )}

                <li>
                    <Link to="/AboutUs" className="block slide-out-menu text-left pb-3" onClick={handleClick}>About Us</Link> 
                </li>
                <li>
                    <Link to="/" className="block slide-out-menu text-left pb-3" onClick={handleClick}>Credits</Link> 
                </li>
            </ul>
        </div>
     );
}
 
export default BurgerMenu;