import { Link } from 'react-router-dom'

const Footer = () => {
    return ( 
        <footer className=' footer m-0 p-0'>
            <Link to="/AboutUs" className="pr-5">ABOUT US</Link> 
            <Link to="/ContactUs" className="">CONTACT US</Link> 
        </footer>

     );
}
 
export default Footer;